(function ($) {

  $.fn.showMoreNews = function () {

    let chunkSize = 20;
    let btn = $(this);
    let numTargetsInitial = $('.newsitem-older.newsitem-hidden').length;

    /**
     * showTarget
     * visiually fades in a target element that initially is display none
     * @param target
     */
    let showTarget = function (target) {
      $(target).hide();
      $(target).removeClass("newsitem-hidden");
      $(target).fadeIn();
    }

    /**
     * restoreState
     * checks what amount of targets had been made visible erilier in the session
     * makes sure these targets are made visible again
     * if all targets have been made visible the show more button is hidden
     * @param target
     */
    let restoreState = function () {

      let numTargetsRevealed = parseInt(_.getSessionStorage("numTargetsRevealed"), 10);

      if (numTargetsRevealed > 0) {
        $('.newsitem-older.newsitem-hidden').each(function (index, element) {
          showTarget(element);
          return (index !== numTargetsRevealed - 1);
        });
      }

      if (numTargetsRevealed === numTargetsInitial) {
        btn.hide();
      }
    }

    // this runs on pageload 
    restoreState();

    /**
     * showNextChunk
     * shows the next chunk of targets
     * hides button if all targets are shown
     * writes the amount of revealed targets in session storage
     * @param target
     */
    let showNextChunk = function (e) {

      let numTargetsRemaining = $('.newsitem-older.newsitem-hidden').length;

      if (numTargetsRemaining > chunkSize) {

        let nextChunk = $('.newsitem-older.newsitem-hidden').slice(0, chunkSize);

        nextChunk.each(function (index, element) {
          showTarget(element);
        });

      } else {
        // show the remaining articles
        $('.newsitem-older.newsitem-hidden').each(function (index, element) {
          showTarget(element);
        });
        // remove the button after all news are displayed
        btn.hide();
      }

      // writes the amount of revealed targets in session storage
      let numTargetsRevealed = numTargetsInitial - $('.newsitem-older.newsitem-hidden').length;
      _.setSessionStorage("numTargetsRevealed", numTargetsRevealed)
    }

    btn.on('click', function (e) {
      showNextChunk(e);
    });

  }


}(jQuery));