(function ($) {


    /**
     * wizard plugin 3
     * Sending form to bob-server with zen endpoint.
     * @returns {jQuery}
     */
    $.fn.bobFormWizard1 = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);

        // Set bob constants
        let c = document.bob.const;
        c.bob_event_namespace = '.bobWizard1';


        // Load the bobMsg plugin
        $(c.bob_frame_form_selector).bobMsg(settings);

        /**
         * Submit form
         * triggers bob-msg to close all open messages.
         * calls _.getFormData and validateFormData
         * triggers bob-msg to show a warning message for invalid input.
         * Finally: triggers next event or calls sendData
         * @param e
         */
        let submitForm = function (e) {

            e.preventDefault();

            let pre = settings.prefix;

            _.submitBtnDisabled(pre, true);

            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            // This informs the message plugin that a new submit event happend
            // So you always get a frash new message on each submit
            _.throwEvent(c.bob_frame_form_selector, c.bob_event_form_submit, {
                'submitForm': settings.classForm
            });

            // get form values
            let formData = _.getFormData(c.bob_frame_form_selector);

            // validate values - extra method
            let valid = validateFormData(formData);

            if (!valid) {
                // enable button again
                _.submitBtnDisabled(pre, false);

                _.throwEvent(c.bob_frame_form_selector, c.bob_event_form_warning);

                return;
            }

            sendData(formData);

        };

        /**
         * send the data to bob-server
         */
        let sendData = function (formData) {

            $('document').spinner('show');
            let pre = settings.prefix;

            let zenData = prepareData(formData);

            $.ajax({
                url: "/zen",
                type: "POST",
                data: zenData,
                cache: false,
                success: function (response) {

                    var e = $.Event('contentchange.bottomize');
                    $(window).trigger(e);

                    // next show success message
                    _.throwEvent(c.bob_frame_form_selector, c.bob_event_frame_content_next, {
                        'src':  settings.successSelector
                    });

                    $('document').spinner('hide');

                },
                error: function (err) {

                    _.throwEvent(c.bob_frame_form_selector, c.bob_event_form_error);

                    // enable button again
                   _.submitBtnDisabled(pre, false);

                    // hide spinner
                    $('document').spinner('hide');
                }
            });
        }

        /**
         * validate form values
         */
        let validateFormData = function (formData) {

            $('.form-group input', c.bob_frame_form_selector).removeClass('is-invalid');

            let validItem1 = false;
            let validItem2 = false;
            let inputName = $('.form-group-name input', c.bob_frame_form_selector);
            let inputEmail = $('.form-group-email input', c.bob_frame_form_selector);

            if (formData.data.name.trim() === '') {
                inputName.addClass('is-invalid');
            } else {
                inputName.removeClass('is-invalid');
                validItem1 = true;
            }

            if (formData.data.email.trim() === '' || _.validateEmail(formData.data.email.trim()) === false) {
                inputEmail.addClass('is-invalid');
            } else {
                inputEmail.removeClass('is-invalid');
                validItem2 = true;
            }


            return validItem1 && validItem2;
        };


        var prepareData = function prepareData(formData) {

            let zData = {
                "name": formData.data.name,
                "email": formData.data.email,
                "description": formData.data.description,
                "sender": formData.data.sender,
                "subject": formData.data.subject,
            }
            
            if (formData.data.mailto) {
                let copyEmails = formData.data.mailto.trim().split(' ');
                zData["mailto"] = copyEmails;
            }

            return zData;
        
          };

        /**
         * Event listeners
         */

        // Reset listeners
        // use same selector and namespace if possible 
        $(c.bob_frame_form_selector).off(c.bob_event_namespace);

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(c.bob_frame_form_selector).on('click' + c.bob_event_namespace, '.bob-wizard-back', function (e) {
            _.throwEvent(c.bob_frame_form_selector, c.bob_event_frame_content_next, {
                'src': '#w1-bob-wizard-form2'
            });
        });

        /**
         *  fired automatically by the form
         *  next: submitForm
         */
        $(c.bob_frame_form_selector).on('submit' + c.bob_event_namespace, function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));