(function ($) {

  $.fn.toggleCollapse = function () {

    function toggleActive (e) {

      var header = $(".bob-frame a[data-target='#" + $(e.target).attr("id") + "']");
      var headerHidden = $(".bob-frame-content a[data-target='#" + $(e.target).attr("id") + "']");
      header.toggleClass("active");
      headerHidden.toggleClass("active");

    }

    $('.collapse-menu').on('shown.bs.collapse hide.bs.collapse', function (e) {
      toggleActive(e);
      // $(".fa-chevron-down").removeClass("fa-chevron-down").addClass("fa-chevron-up");
    });

  }


}(jQuery));