(function ($) {

  $(document).ready(function () {

    // ********** jQuery plugins ************ 

    $('.first-button').on('click', function () {
      $('.animated-icon1').toggleClass('open');
    });

    $('.jumbotron .md-span-style').mdSpanStyles();

    $('body').scrollToAnchors();

    $('#bottom').bottomize();
    
    $('.glyphicons').glyphClone();

    $('.jumbotron a[title^=button], .passage a[title^=button]').mdLinkToButton();
    
    $('.spots a[title^=button]').mdLinkToButton();
    
    $('.stripe a[title^=button]').mdLinkToButton();
    
    $('.jumbotron img').mdImageSize();
    
    $('body').bobButton();

    $('.navbar-nav').menu();

    $('.bob-btn-show-more').showMoreNews();

    $(window).logoAnimation();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('body').toggleCollapse();

    $('.bob-frame-show').bobframe();

    $(".area-btn").on("click", function() {
      var value = $(this).attr("data-area").toLowerCase()
      $("#producttable .row").filter(function() {
        $(this).toggle($(this).attr("data-area").toLowerCase().indexOf(value) > -1)
      });
    });

    // $('.bob-frame-show-nav').bobframeNav();

    // **********************

  });

}(jQuery));
