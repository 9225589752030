(function ($) {

    $.fn.menu = function () {

        /**
         * matchesPage
         * This checks if an element has the same href attribute as the current page
         * @param element // needs to be an jQuery element
         */
        let matchesPage = function (element) {
            let current = location.pathname;

            // here the matching happens
            // index pages are excluded 
            if (element.attr('href').indexOf(current) > -1 && ["/de/", "/de/index.html", "/en/", "/en/index.html"].indexOf(current) === -1) {
                return true
            }
            return false
        }

        // it gives each parent of a navlink class active if it matches the current page
        $('.nav-link').each(function(){
            let $this = $(this);

            if(matchesPage($this)){
                $this.parent().addClass('active');
            } 
        })

        // it gives each dropbdown class active if one of its grandchildrean matches the current page
        $('.dropdown-item').each(function(){
            let $this = $(this);

            if(matchesPage($this)){
                $this.parent().parent().addClass('active');
            } 
        })



        let toggleState = function (e) {
            $('body').toggleClass('nav-active');
        }

        let addState = function () {
            $('body').removeClass('nav-active');
        }

        let expandDropdown = function (e) {
            let $target = $(e.target).parent();
            $target.addClass("is-focused");
        }

        let closeDropdown = function (e) {
            let $target = $(e.target).parent();
            $target.removeClass("is-focused");
        }

        // this used to make the nav accessible on desktop
        // TODO: make dropdown-items focusable in the right order
        if (window.matchMedia('(min-width: 992px)').matches) {

            $($(this)).hover(function () {
                toggleState();
            });

            $($(this)).click(function () {
                toggleState();
            });

            $($(this)).on('touch', function () {
                toggleState();
            });

            $('.nav-link').on("focusin", function () {
                toggleState();
            });

            $('.nav-link').on("focusout", function () {
                addState();
            });

            $(document).on('focusin', '.navbarDropdownMenuLink', function(e) {
                expandDropdown(e);
            });
    
            $(document).on('focusout', '.navbarDropdownMenuLink', function(e) {
                closeDropdown(e);
            });
        }



    }

}(jQuery));