(function ($) {

  $.fn.logoAnimation = function () {

    let scrollTop = 0;

    animate = function () {

      scrollTop = $(window).scrollTop();
      $('.counter').html(scrollTop);

      if (scrollTop >= 30) {
        $('.navbar').addClass('scrolled-nav');
      } else if (scrollTop < 30) {
        $('.navbar').removeClass('scrolled-nav');
      }
    }

    $($(this)).on('scroll', function () {
      animate();
    });

  }


}(jQuery));